<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>套餐管理</p>
			<i>/</i>
			<p @click="jumpPage">套餐列表</p>
			<i>/</i>
			<p class="active">批量改价</p>
		</nav>
		
		<div class="head_search_wrap">
			<div class="search_one_line">
				<div class="left">
					<el-form label-width="60px" @submit.native.prevent>
						<el-form-item label="状态：">
							<el-select v-model="status" placeholder="可选择状态下载表格">
								<el-option label="上架" value="1"></el-option>
								<el-option label="下架" value="0"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="">
							<el-input class="input_medi" v-model="combo_name" clearable placeholder="可输入套餐名称下载表格"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			
			<div class="download_button">
				<p class="reset" @click="downloadTemp">下载表格</p>
				<el-upload class="upload-demo primary_btn" :action="$upEX" ref="upEsx" :show-file-list="false"
					:on-success="upSuccess" :limit="1" :data="commonData">
					<p class="search">上传表格</p>
				</el-upload>
			</div>
			
		</div>
		
		

	</div>
</template>

<script>
	export default {
		name: 'batchChangePrice',
		data() {
			return {
				commonData: {
					'Content-Type': 'application/json'
				},  // 文件上传对象
				keyword: '',  // 关键字搜索
				packageExcleData: [], // 商品表格数据
				status: '', // 下载表格状态
				combo_name: '', // 下载表格套餐名称
			}
		},
		components: {

		},
		created() {
			
		},
		methods: {

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},
			
			// 下载表格
			downloadTemp() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/exporturl`,
					data: {
						combo_name: this.combo_name,
						status: this.status,
					}
				}).then( res => {
					if(res.code == '0'){
						window.location.href = res.data.url;
					}
					else {
						this.$message.error(`${res.msg}`);
					}
				})

			},

			// 表格文件上传成功
			upSuccess(response, file, fileList) {
				console.log(response, file, fileList)
				if (response.code == '0') {
					this.$message({
						message: `${response.msg}`,
						type: 'success'
					});
					this.$refs.upEsx.clearFiles();
				} else {
					this.$message.error(`${response.msg}`);
				}

			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
	
	.search_p{
		width: 64px;
		height: 32px;
		line-height: 32px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		background-color: #EE7B1D;
		margin: 4px 0 0 12px;
		border-radius: 2px;
	}
	
	.download_button{
		display: flex;
		justify-content: flex-start;
		margin-top: 10px;
		.reset {
			border: 1px solid rgba(0, 0, 0, 0.2);
			color: #000;
			opacity: 0.7;
		}
		.search {
			background-color: #EE7B1D;
			color: #fff;
			margin-left: 8px;
		}
		p{
			height: 32px;
			width: 64px;
			border-radius: 2px;
			text-align: center;
			line-height: 32px;
			font-size: 14px;
			cursor: pointer;
		}
	}
	
</style>
